<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="600"
        @keydown.enter="ok"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="wrapperFormModal">
                <v-card flat>
                    <v-card-text>
                        <v-row no-gutters>

                            <v-col cols="12" sm="12" md="3">
                                <label class="f-label">{{$t("Пароль")}}</label>
                            </v-col>

                            <v-col cols="12" sm="12" md="9">
                                <v-text-field
                                    v-model="password"
                                    hide-details
                                    outlined
                                    dense
                                    type="password"
                                >
                                </v-text-field>
                            </v-col>

                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                >
                    {{ $t("Ок") }} 
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "PromptPasswordDlg",
    data () {
        return {
            title: "Введите_пароль",
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            }
        }
    },
    computed: {
        ...mapGetters('global/signing', { visible: 'isPromptPasswordVisible' }),
        password: {
            get: function() {
                return this.$store.getters['global/signing/getPassword'];
            },
            set: function(v) {
                this.$store.commit('global/signing/SET_PASSWORD', v); 
            }
        },
    },
    methods: {
        ...mapActions('global/signing', { ok: 'passwordOk', cancel: 'passwordCancel' })
    }
}
</script>